import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

import PostStyles from "./posts.module.css"
import kebabCase from "lodash/kebabCase";

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={post.frontmatter.title} image={post.frontmatter.image} description={post.frontmatter.description}></SEO>
      {console.log(post.frontmatter.description)}
      <article className={PostStyles.single}>
        <Img fluid={featuredImgFluid} />
        <span className={PostStyles.meta}>{post.frontmatter.date} | {post.frontmatter.categories}</span>
        <h1 className={PostStyles.title}>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter.tags.map(tag => (
          <Link to={`/tags/${kebabCase(tag)}/`} className={PostStyles.tag}>{tag}</Link>
        ))}
      </article>
    </Layout>
  )
}

BlogPost.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        date(formatString: "DD/MM/YYYY")
        tags
        categories
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`